


















































































import AppLayout from "../components/commons/layouts/AppLayout.vue";
import Button from "../components/commons/inputsAndControls/Button.vue";
import Table from "../components/commons/tables/Table.vue";
import TextField from "../components/commons/inputsAndControls/TextField.vue";
import { Component, Vue } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import { alerts, apiClient } from "../utils";
import { api } from "../api";
import { AuthGetters, authNamespace } from "../store/modules/auth";
import { Getter } from "vuex-class";
import { Admin } from "../types/gqlGeneratedPublic";
import {
  UpdateLoggedAdminInput,
  UpdateLoggedAdminMutationVariables,
  UpdateLoggedAdminPasswordInput,
  UpdateLoggedAdminPasswordMutationVariables,
} from "../types/gqlGeneratedPrivate";

@Component({
  components: {
    AppLayout,
    Button,
    Table,
    TextField,
    ValidationObserver,
  },
})
export default class ProfilePage extends Vue {
  @Getter(`${authNamespace}/${AuthGetters.GetAdministrator}`)
  admin!: Admin;

  isFormNameValid = false;
  formLoggedAdmin: UpdateLoggedAdminInput = {
    username: "",
    firstname: "",
    surname: "",
  };

  isFormNewPasswordValid = false;
  formNewPassword: UpdateLoggedAdminPasswordInput = {
    oldPassword: "",
    newPasswordOne: "",
    newPasswordTwo: "",
  };

  mounted() {
    this.formLoggedAdmin = {
      firstname: this.admin.firstname,
      surname: this.admin.surname,
      username: this.admin.username,
    };
  }

  async submitFormName() {
    const result = await this.updateLoggedAdmin(this.formLoggedAdmin);
    if (result.data) {
      alerts.addSuccessAlert("UPDATE_LOGGED_ADMIN", "Uloženo");
    }
  }

  async updateLoggedAdmin(input: UpdateLoggedAdminInput) {
    return await apiClient.callGraphqlPrivate<
      Admin,
      UpdateLoggedAdminMutationVariables
    >({
      ...api.admins.updateLoggedAdmin,
      variables: { input },
    });
  }

  async submitFormNewPassword() {
    const result = await this.updateLoggedAdminPassword(this.formNewPassword);
    if (result.data) {
      alerts.addSuccessAlert("UPDATE_LOGGED_ADMIN_PASSWORD", "Uloženo");
      (this.$refs.formNewPassword as HTMLFormElement).reset();
      this.$nextTick(() => {
        (this.$refs.observerNewPassword as any).reset();
      });
    }
  }

  async updateLoggedAdminPassword(input: UpdateLoggedAdminPasswordInput) {
    return await apiClient.callGraphqlPrivate<
      boolean,
      UpdateLoggedAdminPasswordMutationVariables
    >({
      ...api.admins.updateLoggedAdminPassword,
      variables: { input },
    });
  }
}
